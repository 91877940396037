<nav class="navbar is-primary" role="navigation" aria-label="menu">
    <div class="navbar-brand">
        <a href="https://www.lagodighirla.com" class="navbar-item">
            <img src="../../../assets/logo.png" alt="3Lago Camping">
        </a>
        <div class="navbar-item">
            <h1 class="title is-2">MyGrill</h1>
        </div>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" [class.is-active]="menuOpen"
           (click)="toggleMenu()">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>
    <div class="navbar-menu" [class.is-active]="menuOpen" (click)="menuOpen = false">
        <div class="navbar-start">
            <a routerLink="bookings" routerLinkActive="is-active" class="navbar-item" i18n>Booking</a>
            <a routerLink="tac" routerLinkActive="is-active" class="navbar-item" i18n>Terms & Conditions</a>
            <a routerLink="admin" routerLinkActive="is-active" class="navbar-item" appIsLoggedIn i18n>Bookings</a>
        </div>
        <div class="navbar-end">
            <a class="navbar-item" routerLink="/login">
                <span class="icon">
                    <i class="fa fa-user"></i>
                </span>
                <span i18n>Login</span>
            </a>
        </div>
    </div>
</nav>
<router-outlet></router-outlet>
<app-loader></app-loader>
