import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarComponent} from './shared/calendar/calendar.component';
import {BookingFormComponent} from './shared/booking-form/booking-form.component';
import {FormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module} from 'ng-recaptcha';
import {CAPTCHA_KEY} from '../environments/environment';
import {LoaderComponent} from './shared/loader/loader.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {TaCInputComponent} from './terms-and-conditions/tac-input/tac-input.component';
import {PhoneInputComponent} from './shared/phone-input/phone-input.component';
import {VerifyBookingComponent} from './verify-booking/verify-booking.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {LoginComponent} from './auth/login/login.component';
import {IsLoggedInDirective} from './auth/is-logged-in.directive';
import {FrameComponent} from './shared/frame/frame.component';
import {BookingsComponent} from './admin/bookings.component';
import {AdminCreateBookingComponent} from './admin/admin-create-booking/admin-create-booking.component';
import {CustomerDateChooserComponent} from './customer-date-chooser/customer-date-chooser.component';
import {AuthHeaderInterceptor} from './auth/auth-header.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        CalendarComponent,
        BookingFormComponent,
        LoaderComponent,
        TermsAndConditionsComponent,
        TaCInputComponent,
        PhoneInputComponent,
        VerifyBookingComponent,
        CheckoutComponent,
        LoginComponent,
        IsLoggedInDirective,
        FrameComponent,
        BookingsComponent,
        AdminCreateBookingComponent,
        CustomerDateChooserComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        RecaptchaModule,
        RecaptchaV3Module
    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHeaderInterceptor,
            multi: true
        }, {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: CAPTCHA_KEY
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
