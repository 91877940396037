<section class="hero is-fullheight-with-navbar">
	<div class="hero-body">
		<div class="container has-text-centered">
            <p>
                <span class="icon is-large">
                    <span class="fas fa-10x" [class.fa-check-circle]="valid" [class.fa-exclamation-circle]="!valid"></span>
                </span>
            </p>
            <p class="is-size-3" *ngIf="date">{{date | date:'longDate'}}</p>
        </div>
	</div>
</section>
<app-loader></app-loader>
