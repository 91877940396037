import {Component, forwardRef, Input} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import isValidNumber from 'libphonenumber-js';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PhoneInputComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PhoneInputComponent),
        multi: true
    }]
})
export class PhoneInputComponent implements ControlValueAccessor, Validator {

    private _disabled?: boolean;

    get disabled() {
        return this._disabled ?? false;
    }

    private _value?: string;

    get value() {
        return this._value ?? '';
    }

    set value(value: string) {
        this._value = value;
        this.onChange(value);
    }

    private _required: boolean;

    @Input()
    set required(value: any) {
        this._required = coerceBooleanProperty(value);
    }

    get required() {
        return this._required;
    }

    constructor() {
        this._required = false;
    }

    onChange = (_: string | undefined) => {
    };
    onTouched = () => {
    };

    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnValidatorChange(fn: () => void): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        let errors = {};
        if (this.required && !this.disabled && !control.value)
            errors = {
                ...errors,
                required: true
            };

        if (control.value && !isValidNumber(control.value))
            errors = {
                ...errors,
                pattern: true
            };

        return Object.keys(errors).length ? errors : null;
    }

}
