import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CalendarEvent} from 'calendar-utils';
import {BookingData} from './shared/booking-form/booking-data';
import {firstValueFrom, lastValueFrom, map, retry} from 'rxjs';
import {API_DOMAIN, MAX_GRILLS} from '../environments/environment';
import {Router} from '@angular/router';
import {format} from 'date-fns';
import {CalendarService} from './shared/calendar/calendar.service';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    constructor(private http: HttpClient, private router: Router, private calendarService: CalendarService) {
    }

    async getByMonth(date: Date) {
        return await firstValueFrom(this.http.get<{ [key: number]: number }>(`${API_DOMAIN}/bookings/by-month`, {
            params: {
                month: format(date, 'yyyy-MM')
            }
        }).pipe(map(e => {
            const result: CalendarEvent[] = [];
            for (const k in e)
                if (e[k] >= MAX_GRILLS)
                    result.push({
                        start: this.calendarService.parseDate(k),
                        title: $localize`Fully booked`,
                        allDay: true,
                        color: {
                            primary: 'red',
                            secondary: 'white'
                        }
                    } as CalendarEvent);
            return result;
        })));
    }

    async submitBooking(data: BookingData) {
        const success = this.router.createUrlTree(['/app/bookings/checkout'], {
            queryParams: {
                success: true
            }
        }).toString();
        const failure = this.router.createUrlTree(['/app/bookings/checkout'], {
            queryParams: {
                success: false
            }
        }).toString();
        return await firstValueFrom(this.http.put<{ url: string }>(`${API_DOMAIN}/bookings`, {
            data,
            success: `${window.location.origin}${success}`,
            failure: `${window.location.origin}${failure}`
        }));
    }

    async voidBooking(id: string) {
        return await lastValueFrom(this.http.post<void>(`${API_DOMAIN}/admin/bookings/${id}/void`, null));
    }

    async confirmBooking(id: string, token: string) {
        return await lastValueFrom(this.http.post<void>(`${API_DOMAIN}/bookings/${id}/confirm`, {
            token
        }).pipe(retry({delay: 1000, count: 3})));
    }

    async verifyBooking(id: string, token: string) {
        return await firstValueFrom(this.http.post<{ valid: boolean, date: Date }>(`${API_DOMAIN}/bookings/${id}/verify`, {
            token
        }));
    }

    async adminListBookings(token?: any) {
        let params = new HttpParams();
        for (const [key, value] of Object.entries(token ?? {}))
            params = params.set(key, value as string);

        return await firstValueFrom(this.http.get<{ data: BookingData[], token?: any }>(`${API_DOMAIN}/admin/bookings`, {
            params
        }));
    }

    async adminSubmitBooking(data: BookingData) {
        return await firstValueFrom(this.http.put<void>(`${API_DOMAIN}/admin/bookings`, data));
    }

}
