<div class="navbar">
    <div class="navbar-end">
        <div class="navbar-item">
            <a class="button" routerLink="new" i18n>Add</a>
        </div>
    </div>
</div>
<div class="section">
    <div class="container">
        <table class="table is-fullwidth is-striped is-bordered">
            <thead>
            <tr>
                <th class="is-invisible"></th>
                <th class="is-narrow">Data</th>
                <th>Nome</th>
                <th class="is-narrow">Email</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let booking of bookings">
                <td class="is-narrow">
                    <span class="icon is-clickable" (click)="deleteBooking(booking)">
                        <i class="fa fa-trash"></i>
                    </span>
                </td>
                <td class="is-narrow">{{booking.bookingDate | date}}</td>
                <td>{{booking.firstName}} {{booking.lastName}}</td>
                <td class="has-text-centered">
                    <a [href]="'mailto:' + booking.email">
                        <span class="icon">
                            <i class="fa fa-envelope"></i>
                        </span>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
