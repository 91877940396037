import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const {code, error} = this.route.snapshot.queryParams;
        const promises = [
            new Promise(resolve => setTimeout(resolve, 3000))
        ];

        if (!this.auth.isAuthenticated()) {
            if (code)
                promises.push(this.auth.getTokens(code));
            else if (error)
                return;
            else
                promises.push(this.auth.login());
        }

        Promise.all(promises).then(() => {
            this.router.navigate(['/']).then();
        });
    }

}
