<div class="hero is-fullheight-with-navbar">
    <div class="hero-body">
        <div class="container">
            <div class="columns">
                <div class="column has-text-centered" *ngIf="!success">
                    <p class="title is-1" i18n>Whoops!</p>
                    <p class="subtitle" i18n>Your payment was canceled.</p>
                    <a routerLink="/" i18n>Please try again.</a>
                </div>
                <div class="column has-text-centered" *ngIf="success">
                    <p class="title is-1" i18n>Thank you!</p>
                    <p class="subtitle" i18n>Your payment is being processed.</p>
                    <p i18n>You will receive a confirmation email shortly.</p>
                    <p i18n>You can close this page.</p>
                </div>
            </div>
        </div>
    </div>
</div>
