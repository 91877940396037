import {Component, forwardRef, Input} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'app-tac-input',
    templateUrl: './tac-input.component.html',
    styleUrls: ['./tac-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TaCInputComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => TaCInputComponent),
        multi: true
    }]
})
export class TaCInputComponent implements ControlValueAccessor, Validator {

    private _required: boolean;

    @Input()
    set required(value: any) {
        this._required = coerceBooleanProperty(value);
    }

    get required() {
        return this._required;
    }

    private _disabled = false;

    get disabled() {
        return this._disabled;
    }

    checked = false;
    showTaCModal: boolean;

    constructor() {
        this.showTaCModal = false;
        this._required = false;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this._required && !this.checked)
            return {
                required: true
            };
        else
            return null;
    }

    onChange = (_: boolean) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    writeValue(obj: boolean): void {
        this.checked = obj;
    }

}
