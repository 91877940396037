import {Component, OnInit} from '@angular/core';
import {BookingService} from '../booking.service';
import {BookingData} from '../shared/booking-form/booking-data';
import {ToastService} from '../shared/toast/toast.service';
import {LoaderService} from '../shared/loader/loader.service';

@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

    private key?: any;
    private _bookings?: BookingData[];

    get bookings() {
        return this._bookings ?? [];
    }

    constructor(private bookingService: BookingService, private toast: ToastService, private loader: LoaderService) {
    }

    ngOnInit(): void {
        const promise = this.loadBookings();
        this.loader.emit({promise});
        promise.then(() => {
            while (!!this.key)
                this.loadBookings(this.key).then();
        });
    }

    private async loadBookings(key?: string) {
        const {data, token} = await this.bookingService.adminListBookings(key);
        this.key = token;
        this._bookings = [...this.bookings, ...data]
            .sort((a, b) => {
                if (a.bookingDate === b.bookingDate) {
                    if (a.firstName === b.firstName) {
                        if (a.lastName === b.lastName)
                            return 0;
                        else if (a.lastName! < b.lastName!)
                            return -1;
                    } else if (a.firstName! < b.firstName!)
                        return -1;
                } else if (a.bookingDate! < b.bookingDate!)
                    return -1;

                return 1;
            });
    }

    async deleteBooking(booking: BookingData) {
        try {
            await this.bookingService.voidBooking(booking.bookingId!);
            this._bookings = this._bookings?.filter(e => e !== booking);
        } catch (err) {
            this.toast.warn($localize`An error occurred.`);
        }
    }

}
