import {Component} from '@angular/core';
import {LoaderService} from '../../shared/loader/loader.service';
import {BookingService} from '../../booking.service';
import {BookingData} from '../../shared/booking-form/booking-data';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {firstValueFrom} from 'rxjs';
import {MonthViewDay} from 'calendar-utils';
import {ToastService} from '../../shared/toast/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CalendarService} from '../../shared/calendar/calendar.service';

@Component({
    selector: 'app-admin-create-booking',
    templateUrl: './admin-create-booking.component.html',
    styleUrls: ['./admin-create-booking.component.scss']
})
export class AdminCreateBookingComponent {

    data: BookingData;

    constructor(private loader: LoaderService, private bookingService: BookingService, private route: ActivatedRoute,
                private router: Router, private captchaService: ReCaptchaV3Service, private toast: ToastService,
                private calendarService: CalendarService) {
        this.data = {
            bookingDate: this.calendarService.formatDate(new Date()),
            token: undefined
        };
    }

    async submit() {
        this.loader.emit({promise: this.create()});
    }

    private async create() {
        this.data.token = await firstValueFrom(this.captchaService.execute('adminCreateBooking'));
        try {
            await this.bookingService.adminSubmitBooking(this.data);
            this.toast.success($localize `Success`);
            await this.router.navigate(['.'], {
                relativeTo: this.route.parent
            });
        } catch (err) {
            this.toast.warn($localize `An error occurred.`);
        }
    }

    onDayClicked({date}: MonthViewDay) {
        this.data.bookingDate = this.calendarService.formatDate(date);
    }

    get selected() {
        const {bookingDate} = this.data;

        if (!bookingDate)
            throw new Error('No date provided');

        return this.calendarService.parseDate(bookingDate);
    }

}
