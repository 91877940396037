import {Injectable} from '@angular/core';
import {toast, ToastType} from 'bulma-toast';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor() {
    }

    success(message: string) {
        this.showToast('is-success', message);
    }

    warn(message: string) {
        this.showToast('is-danger', message);
    }

    private showToast(type: ToastType, message: string) {
        toast({
            type,
            message,
            pauseOnHover: true,
            position: 'bottom-right',
            duration: 3000,
            dismissible: true
        });
    }
}
