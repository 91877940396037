import {Component, OnDestroy, OnInit} from '@angular/core';
import {BookingService} from '../booking.service';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom, pluck, Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {LoaderService} from '../shared/loader/loader.service';

@Component({
    selector: 'app-verify-booking',
    templateUrl: './verify-booking.component.html',
    styleUrls: ['./verify-booking.component.scss']
})
export class VerifyBookingComponent implements OnInit, OnDestroy {

    private subscription?: Subscription;

    constructor(private bookingService: BookingService, private route: ActivatedRoute, private recaptcha: ReCaptchaV3Service,
                private loader: LoaderService) {
    }

    private _valid?: boolean;

    get valid() {
        return this._valid;
    }

    private _date?: Date;

    get date() {
        return this._date ?? '';
    }

    ngOnInit(): void {
        this.subscription = this.route.params
            .pipe<string>(pluck('id'))
            .subscribe((id) => this.loader.emit({
                promise: this.verifyBooking(id),
                placeholder: $localize`Verifying booking validity...`
            }));
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private async verifyBooking(id: string) {
        this._valid = undefined;
        const token = await firstValueFrom(this.recaptcha.execute('verifyBooking'));
        const {valid, date} = await this.bookingService.verifyBooking(id, token);
        this._valid = valid;
        this._date = date;
    }

}
