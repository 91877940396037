import {Component, OnDestroy, OnInit} from '@angular/core';
import {BookingData} from './booking-data';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subscription} from 'rxjs';
import {BookingService} from '../../booking.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ToastService} from '../toast/toast.service';
import {LoaderService} from '../loader/loader.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-booking-form',
    templateUrl: './booking-form.component.html',
    styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit, OnDestroy {

    data: BookingData;
    tac?: boolean;
    private subscription?: Subscription;

    constructor(private router: Router, private route: ActivatedRoute, private bookingService: BookingService,
                private recaptchaService: ReCaptchaV3Service, private toast: ToastService, private loader: LoaderService) {
        this.data = {
            token: undefined
        };
    }

    ngOnInit(): void {
        this.subscription = this.route.queryParamMap.subscribe((map) => {
            const date = map.get('date');

            if (!date)
                throw new Error('No date provided');

            this.data.bookingDate = date;
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    async onSubmit() {
        try {
            this.loader.emit({
                promise: this.createOrder(),
                placeholder: $localize`Your order is being created`
            });
        } catch (err) {
            this.toast.warn($localize`An error occurred.`);
            throw err;
        }
    }

    private async createOrder() {
        this.data.token = await firstValueFrom(this.recaptchaService.execute('putBooking'));
        try {
            const {url} = await this.bookingService.submitBooking(this.data);
            window.location.href = url;
        } catch (err) {
            console.error(err);
            switch ((err as HttpErrorResponse).status) {
                case 400:
                    this.toast.warn($localize`Invalid data provided`);
                    break;
                case 412:
                    this.toast.warn($localize`No available grills left`);
                    break;
                case 403:
                    this.toast.warn($localize`Invalid captcha`);
                    break;
                case 409:
                    this.toast.warn($localize`You already booked this grill`);
                    break;
                default:
                    this.toast.warn($localize`Something went wrong. Please try again later`);
            }
        }
    }

}
