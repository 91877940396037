import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from './loader.service';
import {firstValueFrom, interval, Subscription} from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {

    private _placeholder?: string;
    private _loading?: boolean;
    private subscription?: Subscription;

    get loading() {
        return this._loading;
    }

    get placeholder() {
        return this._placeholder;
    }

    constructor(private service: LoaderService) {
        this._loading = false;
        this.subscription = this.service.subscribe((e) => {
            this._placeholder = e.placeholder;
            this._loading = true;
            Promise.all([
                e.promise,
                firstValueFrom(interval(1000))
            ]).then(() => this._loading = false, () => this._loading = false);
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

}
