<div class="section">
    <div class="container">
        <h1 class="title" i18n>Pic-nic area rules</h1>
        <p>
            <span i18n>It is strictly forbidden to bring your own grills.</span>
        </p>
        <p>
            <span i18n>It is mandatory to book your BBQ and table on our dedicated Web portal,</span>&nbsp;
            <a href="https://www.lagodighirla.com">www.lagodighirla.com</a>&nbsp;
            <span i18n>provided that only charcoal is used to feed the grill.</span>&nbsp;
            <span i18n>In order to keep nearby trees safe, no branches or wood are allowed to be burnt.</span>
        </p>
        <p>
            <span i18n>Access to the area is permitted from 9:00 to 19:30.</span>
        </p>
        <p>
            <span i18n>Within the area perimeter, sport is permitted only in the dedicated fields or courts, avoiding crowd.</span>
        </p>
        <p>
            <span i18n>Customers are required to behave and to not cause trouble to other guests.</span>&nbsp;
            <span i18n>Should this fail, management has the right to remove subject without reimbursement of the ticket.</span>
        </p>
        <p>
            <span i18n>It is strictly forbidden to introduce loud or percussive musical instruments in the area, as well as music players of any kind.</span>
        </p>
        <p>
            <span i18n>It is permitted to introduce docile dogs in the area, provided they are leashed the whole time.</span>&nbsp;
            <span i18n>Should the dog foul, owners are required to clean up.</span>&nbsp;
            <span i18n>Should the dog be too loud, aggressive or troublesome to other guests, management has the right to ask for it to be removed from the area.</span>&nbsp;
            <span i18n>In case of damage to the infrastructure, reimbursement will be requested.</span>
        </p>
        <p>
            <span i18n>The town implemented waste sorting: guests are kindly asked to use containers in the area for different waste types.</span>
        </p>
        <p>
            <span i18n>In case of bad weather, no refund will be issued.</span>
        </p>
        <h2 class="title">Price list</h2>
        <div class="columns is-vcentered">
            <div class="column is-half-desktop">
                <ul class="has-text-weight-bold">
                    <li>
                        <div class="columns">
                            <div class="column"><span i18n>Adults</span></div>
                            <div class="column is-one-fifth has-text-right"><span>€ 3.00</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="columns">
                            <div class="column"><span i18n>Children (0-6)</span></div>
                            <div class="column is-one-fifth has-text-right"><span>€ 1.00</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="columns">
                            <div class="column"><span i18n>Parking</span></div>
                            <div class="column is-one-fifth has-text-right"><span>€ 2.00</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="columns">
                            <div class="column"><span i18n>Dogs</span></div>
                            <div class="column is-one-fifth has-text-right"><span>€ 1.00</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="columns">
                            <div class="column"><span i18n>Pic-nic table</span></div>
                            <div class="column is-one-fifth has-text-right"><span>€ 6.00</span></div>
                        </div>
                    </li>
                    <li class="mt-3">
                        <div class="columns">
                            <div class="column"><span i18n>Grill (table included)</span></div>
                            <div class="column is-one-fifth has-text-right"><span>€ 50.00</span></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="column has-text-centered">
                <figure class="image is-128x128 m-auto"><img alt="QR" src="assets/qr.jpg"></figure>
            </div>
        </div>
    </div>
</div>
