import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {API_DOMAIN} from 'src/environments/environment';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request.url.startsWith(API_DOMAIN) || !this.auth.isAuthenticated())
            return next.handle(request);

        let headers = request.headers;
        headers = headers.set('Authorization', this.auth.getAuthToken());

        const authorizedRequest = request.clone({
            headers
        });

        return next.handle(authorizedRequest);
    }
}
