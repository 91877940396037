import {Directive, ElementRef, OnInit, Optional} from '@angular/core';
import {AuthService} from './auth.service';
import {FormControl} from '@angular/forms';

@Directive({
    selector: '[appIsLoggedIn]'
})
export class IsLoggedInDirective implements OnInit {

    constructor(private service: AuthService, @Optional() private control: FormControl, private element: ElementRef) {
    }

    ngOnInit() {
        if (this.service.isAuthenticated())
            return;

        if (this.control)
            this.control.disable();
        else
            this.element.nativeElement.style.display = 'none';
    }

}
