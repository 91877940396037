import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BookingFormComponent} from './shared/booking-form/booking-form.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {VerifyBookingComponent} from './verify-booking/verify-booking.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {LoginComponent} from './auth/login/login.component';
import {FrameComponent} from './shared/frame/frame.component';
import {AuthGuard} from './auth/auth.guard';
import {BookingsComponent} from './admin/bookings.component';
import {AdminCreateBookingComponent} from './admin/admin-create-booking/admin-create-booking.component';
import {CustomerDateChooserComponent} from './customer-date-chooser/customer-date-chooser.component';

const routes: Routes = [{
    path: '',
    pathMatch: 'full',
    redirectTo: 'app'
}, {
    path: 'app',
    component: FrameComponent,
    children: [{
        path: '',
        pathMatch: 'full',
        redirectTo: 'bookings'
    }, {
        path: 'bookings',
        children: [{
            path: '',
            pathMatch: 'full',
            redirectTo: 'calendar'
        }, {
            path: 'calendar',
            component: CustomerDateChooserComponent
        }, {
            path: 'create',
            component: BookingFormComponent
        }, {
            path: 'checkout',
            component: CheckoutComponent
        }]
    }, {
        path: 'tac',
        component: TermsAndConditionsComponent
    }, {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [{
            path: '',
            pathMatch: 'full',
            redirectTo: 'bookings'
        }, {
            path: 'bookings',
            children: [{
                path: '',
                pathMatch: 'full',
                component: BookingsComponent
            }, {
                path: 'new',
                component: AdminCreateBookingComponent
            }]
        }]
    }]
}, {
    path: 'verify/:id',
    component: VerifyBookingComponent
}, {
    path: 'login',
    component: LoginComponent
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
