import {Injectable} from '@angular/core';
import {
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router, CanActivate
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {

    constructor(private auth: AuthService, private router: Router) {
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isLoggedIn();
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isLoggedIn();
    }

    private isLoggedIn() {
        if (this.auth.isAuthenticated())
            return true;
        else
            return this.router.createUrlTree(['/login']);
    }

}
