<div class="columns is-centered is-mobile">
    <div class="column is-narrow">
        <button (click)="previousMonth()" class="button" type="button">
            <span class="icon is-small">
                <i class="fas fa-chevron-left"></i>
            </span>
        </button>
    </div>
    <div class="column is-narrow">
        <span class="title">{{date | date:'MMMM y' | titlecase}}</span>
    </div>
    <div class="column is-narrow">
        <button (click)="nextMonth()" class="button" type="button">
            <span class="icon is-small">
                <i class="fas fa-chevron-right"></i>
            </span>
        </button>
    </div>
</div>
<mwl-calendar-month-view
        (beforeViewRender)="disableDays($event.body)"
        (dayClicked)="dayClicked.emit($event.day)"
        [cellTemplate]="dayTemplate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh"
        [viewDate]="date"
        [weekStartsOn]="1"
></mwl-calendar-month-view>

<ng-template
    #dayTemplate
    let-day="day"
    let-locale="locale"
>
    <div [attr.aria-label]="{ day: day, locale: locale } | calendarA11y: 'monthCell'"
         [class.is-active-day]="day.date.getTime() === activeDay.getTime()"
         class="cal-cell-top">
        <span class="cal-day-number">{{day.date | calendarDate: 'monthViewDayNumber':locale}}</span>
    </div>
    <div *ngIf="day.events.length > 0" class="cal-events">
        <span class="is-size-7 is-danger" i18n>Fully booked</span>
    </div>
</ng-template>
