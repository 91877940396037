import {Component, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, pluck, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {BookingService} from '../booking.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

    private subscription?: Subscription;
    success = false;

    constructor(private route: ActivatedRoute, private bookingService: BookingService, private recaptcha: ReCaptchaV3Service) {
    }

    ngOnInit(): void {
        this.subscription = this.route.queryParams
            .subscribe((params) => {
                const {success, id} = params;
                this.success = coerceBooleanProperty(success);
                firstValueFrom(this.recaptcha.execute('paymentApproved')).then((token) => {
                    if (this.success)
                        this.bookingService.confirmBooking(id, token).then();
                    else
                        this.bookingService.voidBooking(id).then();
                });
            });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

}
