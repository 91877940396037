import {Component} from '@angular/core';
import {MonthViewDay} from 'calendar-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {CalendarService} from '../shared/calendar/calendar.service';

@Component({
    selector: 'app-customer-date-chooser',
    templateUrl: './customer-date-chooser.component.html',
    styleUrls: ['./customer-date-chooser.component.scss']
})
export class CustomerDateChooserComponent {

    constructor(private router: Router, private route: ActivatedRoute, private calendar: CalendarService) {
    }

    async onDayClicked(event: MonthViewDay) {
        const {date} = event;

        if (this.calendar.isValidDate(event))
            await this.router.navigate(['create'], {
                relativeTo: this.route.parent,
                queryParams: {
                    date: this.calendar.formatDate(date)
                }
            });
    }

}
