import {Injectable} from '@angular/core';
import {MonthViewDay} from 'calendar-utils';
import {format, parse, startOfToday} from 'date-fns';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    private static readonly FORMAT = 'yyyy-MM-dd';

    constructor() {
    }

    isValidDate(day: MonthViewDay) {
        switch (day.date.getMonth()) {
            case 0:
            case 1:
            case 2:
            case 9:
            case 10:
            case 11:
                return false;
            default:
                return !day.isPast && !day.events?.length;
        }
    }

    formatDate(date: Date) {
        return format(date, CalendarService.FORMAT);
    }

    parseDate(date: string) {
        return parse(date, CalendarService.FORMAT, startOfToday());
    }

}
