import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService extends EventEmitter<{
    promise: Promise<any>,
    placeholder?: string
}> {

    constructor() {
        super();
    }

}
