<div class="field">
    <div class="control">
        <label class="checkbox">
            <input type="checkbox" [required]="required" [(ngModel)]="checked" (ngModelChange)="onChange($event)"
                   (focusin)="onTouched()" [disabled]="disabled">&nbsp;
            <span i18n>I accept all</span>&nbsp;
        </label>
        <a (click)="showTaCModal = true" i18n>Terms and Conditions</a>
    </div>
</div>

<div class="modal" [class.is-active]="showTaCModal">
    <div class="modal-background" (click)="showTaCModal= false"></div>
    <div class="modal-card">
        <div class="box">
            <app-terms-and-conditions></app-terms-and-conditions>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="showTaCModal = false"></button>
</div>
