<div class="section">
    <div class="container">
        <form class="box" (submit)="form.valid && onSubmit();" #form="ngForm">
            <h2 class="title is-3">{{data.bookingDate | date:'longDate' | titlecase}}</h2>
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label class="label" i18n>First name:</label>
                        <div class="control">
                            <input class="input" type="text" name="firstName" [(ngModel)]="data.firstName" required
                                   #firstName="ngModel" [class.is-danger]="firstName.invalid">
                        </div>
                        <p class="help is-danger" *ngIf="firstName.errors?.['required']" i18n>Mandatory field</p>
                    </div>
                    <div class="field">
                        <label class="label" i18n>Last name:</label>
                        <div class="control">
                            <input class="input" type="text" name="lastName" [(ngModel)]="data.lastName" required
                                   #lastName="ngModel" [class.is-danger]="lastName.invalid">
                        </div>
                        <p class="help is-danger" *ngIf="lastName.errors?.['required']" i18n>Mandatory field</p>
                    </div>
                    <div class="field">
                        <label class="label" i18n>E-mail:</label>
                        <div class="control">
                            <input class="input" type="email" name="email" [(ngModel)]="data.email" required
                                   pattern="^\w+(\.\w+)*@\w+(\.\w+)*(\.\w{2,})$" #email="ngModel"
                                   [class.is-danger]="email.invalid">
                        </div>
                        <p class="help is-danger" *ngIf="email.errors?.['required']" i18n>Mandatory field</p>
                        <p class="help is-danger" *ngIf="email.errors?.['pattern']" i18n>E-mail is not valid</p>
                    </div>
                    <div class="field">
                        <label class="label" i18n>Phone:</label>
                        <div class="control">
                            <app-phone-input class="input" name="phone" [(ngModel)]="data.phone" #phone="ngModel"
                                             [class.is-danger]="phone.invalid"></app-phone-input>
                        </div>
                        <p class="help is-danger" *ngIf="phone.errors?.['pattern']" i18n>Phone is not valid</p>
                    </div>
                    <br/>
                    <app-tac-input [(ngModel)]="tac" name="tac" required></app-tac-input>
                </div>
            </div>
            <div class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <div class="level-item">
                        <button class="button is-primary" type="submit" [disabled]="!form.valid" i18n>Proceed to
                            checkout
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
