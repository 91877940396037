import {Component} from '@angular/core';

@Component({
    selector: 'app-frame',
    templateUrl: './frame.component.html',
    styleUrls: ['./frame.component.scss']
})
export class FrameComponent {

    menuOpen = false;

    constructor() {
    }

    toggleMenu() {
        this.menuOpen = !this.menuOpen;
    }

}
