<form #form="ngForm" (submit)="form.valid && submit()">
    <div class="navbar">
        <div class="navbar-start">
            <div class="navbar-item">
                <a class="button" routerLink=".." i18n>Go back</a>
            </div>
        </div>
        <div class="navbar-end">
            <div class="navbar-item">
                <button type="submit" class="button is-success" i18n>Create</button>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="container block">
            <div class="columns">
                <div class="column is-half">
                    <div class="field">
                        <label class="label" i18n>First name:</label>
                        <div class="control">
                            <input class="input" type="text" name="firstName" [(ngModel)]="data.firstName"
                                   #firstName="ngModel" [class.is-danger]="firstName.invalid" required>
                        </div>
                        <p class="help is-danger" *ngIf="firstName.errors?.['required']" i18n>Mandatory field</p>
                    </div>
                </div>
                <div class="column is-half">
                    <div class="field">
                        <label class="label" i18n>Last name:</label>
                        <div class="control">
                            <input class="input" type="text" name="lastName" [(ngModel)]="data.lastName"
                                   #lastName="ngModel" [class.is-danger]="lastName.invalid" required>
                        </div>
                        <p class="help is-danger" *ngIf="lastName.errors?.['required']" i18n>Mandatory field</p>
                    </div>
                </div>
            </div>
            <div class="field">
                <label class="label" i18n>E-mail:</label>
                <div class="control">
                    <input type="email" class="input" name="email" [(ngModel)]="data.email" #email="ngModel"
                           [class.is-danger]="email.invalid" required>
                </div>
                <p class="help is-danger" *ngIf="email.errors?.['required']" i18n>Mandatory field</p>
            </div>
        </div>
        <div class="container block">
            <input type="hidden" name="bookingDate" required [(ngModel)]="data.bookingDate">
            <app-calendar (dayClicked)="onDayClicked($event)" [activeDay]="selected"></app-calendar>
        </div>
    </div>
</form>
