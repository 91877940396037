import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_DOMAIN, AUTH_DOMAIN} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {firstValueFrom} from 'rxjs';
import {ClientIdResponse} from './client-id-response';
import {OAuthTokens} from './oauth-tokens';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private challenge?: string;

    constructor(private http: HttpClient) {
    }

    set pkceChallenge(challenge: string) {
        this.challenge = challenge;
    }

    get pkceChallenge(): string {
        const code = this.challenge ?? '';
        this.challenge = undefined;
        return code;
    }

    private getRedirectUri(): string {
        const {protocol, host} = window.location;
        return `${protocol}//${host}/login`;
    }

    async login(): Promise<void> {
        let params = new HttpParams();
        params = params.set('client_id', await this.getClientId());
        params = params.set('response_type', 'code');
        params = params.set('redirect_uri', this.getRedirectUri());

        window.location.replace(`${AUTH_DOMAIN}/oauth2/authorize?${params.toString()}`);
    }

    async getTokens(authorizationCode: string): Promise<void> {
        let params = new HttpParams();
        params = params.set('client_id', await this.getClientId());
        params = params.set('grant_type', 'authorization_code');
        params = params.set('code', authorizationCode);
        params = params.set('redirect_uri', this.getRedirectUri());

        const tokens = await firstValueFrom(this.http.post<OAuthTokens>(`${AUTH_DOMAIN}/oauth2/token`, params)
            .pipe(map(r => ({
                idToken: r.id_token,
                accessToken: r.access_token,
                tokenType: r.token_type,
                expiresAt: new Date(Date.now() + r.expires_in * 1000).toString()
            })))
        );
        for (const [key, value] of Object.entries(tokens))
            localStorage.setItem(key, value);
    }

    private async getClientId(): Promise<string> {
        return (await firstValueFrom(this.http.get<ClientIdResponse>(`${API_DOMAIN}/auth/client-id`))).clientId;
    }

    getIdToken(): string {
        const tokenType = localStorage.getItem('tokenType');
        const idToken = localStorage.getItem('idToken');
        return `${tokenType} ${idToken}`;
    }

    getAuthToken(): string {
        const tokenType = localStorage.getItem('tokenType');
        const accessToken = localStorage.getItem('accessToken');
        return `${tokenType} ${accessToken}`;
    }

    isAuthenticated(): boolean {
        const expiresAt = localStorage.getItem('expiresAt');
        if (!expiresAt)
            return false;
        return (new Date(expiresAt).getTime() ?? 0) > Date.now();
    }

}
