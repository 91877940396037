import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {addMonths, startOfToday} from 'date-fns';
import {CalendarEvent, MonthViewDay} from 'calendar-utils';
import {BookingService} from '../../booking.service';
import {LoaderService} from '../loader/loader.service';
import {CalendarMonthViewDay} from 'angular-calendar';
import {CalendarService} from './calendar.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {

    @Output()
    readonly dayClicked = new EventEmitter<MonthViewDay>();
    date = new Date();
    refresh = new Subject();
    private subscription?: Subscription;

    constructor(private route: ActivatedRoute, private router: Router, private bookingService: BookingService,
                @Inject(LOCALE_ID) private localeId: string, private loader: LoaderService,
                private calendar: CalendarService) {
        this._events = [];
    }

    private _activeDay?: Date;

    get activeDay(): Date {
        return this._activeDay ?? startOfToday();
    }

    @Input()
    set activeDay(value: Date | undefined) {
        this._activeDay = value;
    }

    private _events: CalendarEvent[];

    get events() {
        return this._events;
    }

    get locale() {
        return this.localeId;
    }

    ngOnInit() {
        this.subscription = this.route.queryParamMap
            .subscribe((map) => {
                const date = map.get('date');
                if (date) {
                    this.date = this.calendar.parseDate(date);
                    this.loader.emit({
                        promise: this.bookingService
                            .getByMonth(this.date)
                            .then((res) => this._events = res)
                    });
                } else
                    this.router.navigate([], {
                        relativeTo: this.route,
                        replaceUrl: true,
                        queryParams: {
                            date: this.calendar.formatDate(startOfToday())
                        }
                    }).then();
            });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    async nextMonth() {
        await this.router.navigate([], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: {
                date: this.calendar.formatDate(addMonths(this.date, 1))
            }
        });
    }

    async previousMonth() {
        await this.router.navigate([], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: {
                date: this.calendar.formatDate(addMonths(this.date, -1))
            }
        });
    }

    disableDays(days: CalendarMonthViewDay[]) {
        days.forEach(day => {
            if (!this.calendar.isValidDate(day))
                day.cssClass = 'cal-disabled';
        });
    }

}
